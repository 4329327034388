import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { useQuery } from '../../styles/breakpoints';
import { graphql, useStaticQuery } from 'gatsby';

interface Styles {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  objectFitOpt?: string;
  marginRight?: string;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  handleOnLoad?: () => void;
}

interface Props extends Styles {
  src: string;
  alt: string;
  mobile_src?: string;
  tablet_src?: string;
  imageHeightDesktop?: string;
}

const Img = styled(GatsbyImage)<Styles>`
  display: block;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  max-height: ${props => props.maxHeight || 'none'};
  max-width: ${props => props.maxWidth || 'none'};
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginTop || 0};
  margin-left: ${props => props.marginLeft || 0};
  margin-right: ${props => props.marginRight || 0};
`;

const Picture = styled.picture<{
  alignCenter: boolean;
  justifyCenter: boolean;
}>`
  ${({ alignCenter }) =>
    alignCenter ? 'display: flex; align-items: center;' : ''}
  ${({ justifyCenter }) =>
    justifyCenter ? 'display: flex; justify-content: center;' : ''}
`;

export const DynamicImage: React.FC<Props> = ({
  src,
  alt,
  mobile_src,
  tablet_src,
  alignCenter = false,
  justifyCenter = false,
  imageHeightDesktop,
  height,
  objectFitOpt = 'contain',
  handleOnLoad,
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { extension: { regex: "/jpeg|jpg|png|gif|svg/" } }
      ) {
        edges {
          node {
            extension
            relativePath
            publicURL
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const { isMobile } = useQuery();

  const imageSrc = isMobile ? mobile_src : src;
  const gatsbyImage = data?.images?.edges.find(
    (image: any) =>
      image.node?.relativePath === imageSrc || image.node?.relativePath === src,
  );

  return !gatsbyImage ? null : !gatsbyImage.node.childImageSharp &&
    gatsbyImage.node.extension === 'svg' ? (
    <img src={gatsbyImage.node.publicURL} loading="lazy" {...rest} />
  ) : (
    <Img
      onLoad={handleOnLoad}
      image={getImage(gatsbyImage?.node) as IGatsbyImageData}
      objectFit={objectFitOpt}
      alt={alt}
      loading={'lazy'}
      maxHeight={height}
      {...rest}
    />
  );
};
