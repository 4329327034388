import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile, smMobile, useQuery } from 'styles/breakpoints';

import Text from './Text';

import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from './images/DynamicImage';

interface ReviewsProps {
  title: string;
  reviews: string[];
  starImgUrl?: string;
  starImgUrlAlt: string;
  onClick?: () => void;
  mobileTitleAlt?: string;
  bgColor?: string;
}

const Container = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${mobile} {
    padding-top: 0;
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
  @media ${mobile} {
    max-width: 21rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const StarImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 16.826875rem;
  @media ${mobile} {
    width: 11rem;
  }
`;

const Title = styled(Text).attrs({
  type: 'h1',
})`
  font-size: 2rem;
  max-width: 560px;
  line-height: 2.375rem;
  text-align: center;
  margin: 0 auto;
  font-weight: 800;

  @media ${mobile} {
    font-size: 1.625rem;
    line-height: 1.625rem;
  }
`;

const SwiperStyled = styled(Swiper)`
  margin-top: 48px;

  .swiper-slide {
    margin-bottom: 2.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: ${({ theme }) => theme.colors.light60};

    &-active {
      background-color: ${({ theme }) => theme.colors.primary};
      width: 8px;
    }
  }

  .swiper-wrapper {
    margin-bottom: 3.375rem;
  }

  @media ${mobile} {
    margin-top: 1.5rem;

    .swiper-wrapper {
      margin-bottom: 1.875rem;
    }
  }
`;

const WhatYouGet = styled(Text)`
  font-size: 0.675rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: -1.25rem;
`;

const SlideContainer = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin: 0 1rem;
`;

const DynamicImageStyled = styled(DynamicImage)``;

const Reviews: FC<ReviewsProps> = ({
  title,
  reviews,
  starImgUrl,
  starImgUrlAlt,
  onClick,
  mobileTitleAlt,
  bgColor = '#ffffff',
}) => {
  const { isMobile } = useQuery();
  return (
    <Container bgColor={bgColor}>
      <InnerWrapper>
        {!!starImgUrl && (
          <StarImageContainer>
            <StyledDynamicImage src={starImgUrl} alt={starImgUrlAlt} />
          </StarImageContainer>
        )}
        {mobileTitleAlt ? (
          <WhatYouGet>{mobileTitleAlt}</WhatYouGet>
        ) : (
          <Title>{title}</Title>
        )}

        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove
          effect="slide"
          slidesPerView={1}
          spaceBetween={0}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <SlideContainer>
                <DynamicImageStyled src={review} alt="Slide" />
              </SlideContainer>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </InnerWrapper>
    </Container>
  );
};

export default Reviews;
