import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

const DEFAULT_FLOW = 'default';

interface Props {
  id: string | string[];
  variants: Record<string, React.ReactNode | string>;
}

export const Experiments: React.FC<Props> = ({ id, variants }) => {
  const { experiments } = useSelector((s: AppState) => s.funnel);
  const variant = useMemo(() => {
    let experiment = null;

    if (experiments) {
      if (typeof id === 'string' && experiments[id]) {
        experiment = variants[id];
      } else if (typeof id === 'object') {
        const variant = id.find(i => experiments[i] && variants[i]);

        if (variant) {
          experiment = variants[variant];
        }
      }
    }

    if (!experiment && variants[DEFAULT_FLOW]) {
      experiment = variants[DEFAULT_FLOW];
    }

    return experiment;
  }, [id, variants, experiments]);

  return <>{variant}</>;
};
