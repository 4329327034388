export async function hashStringToSHA256(string: string) {
  const utf8 = new TextEncoder().encode(string);

  return crypto.subtle.digest('SHA-256', utf8).then(hashBuffer => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(bytes => bytes.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  });
}

export const sanitizeUrlParam = (paramValue: string) => {
  paramValue = paramValue.replace(/[^a-zA-Z0-9\-_~.!]/g, '');
  paramValue = paramValue.trim();
  paramValue = paramValue.replace(/^["']|["']$/g, '');
  return paramValue;
};
