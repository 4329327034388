import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { mobile, useQuery } from 'styles/breakpoints';

interface HeadlineProps {
  bodyText: string;
  bgColor?: string;
  textColor?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  width: 100vw;
  padding: 1.5rem 0;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.darkPurple};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mobile} {
    padding: 1rem 0;
  }
`;

const BodyText = styled(Text)<{ textColor?: string }>`
  color: ${props => (props.textColor ? props.textColor : 'dark100')};
  margin: 0 1rem;
`;

const Headline: FC<HeadlineProps> = ({ bodyText, bgColor, textColor }) => {
  const { isMobile } = useQuery();

  return (
    <Container bgColor={bgColor}>
      <BodyText type={isMobile ? 'buttonTextM' : 'h3500'} textColor={textColor}>
        {bodyText}
      </BodyText>
    </Container>
  );
};

export default React.memo(Headline);
