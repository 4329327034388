import React from 'react';
import styled from 'styled-components';
import '../assets/fonts/fonts.css';
import { DynamicImage, Seo, Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import { VideoTestimonials } from './start/checkout/components/VideoTestimonials';
import { ReviewCards } from './start/checkout/components/ReviewCards';
import PeopleAskUs from 'components/PeopleAskUs';
import { ResultsFooter } from './start/results/components/ResultsFooter';
import { WithWithoutLanding } from './sections/WithWithoutLanding';
import LCFooter from 'components/LCFooter';
import Landing from './start/quiz/components/Landing';
import { navigate } from 'gatsby';
import StartNowButton from './start/results/components/StartNowButton';

const items = [
  {
    icon: 'quiz/tape.svg',
    title: 'Spermidine',
    subtitle:
      'Spermidine is a natural compound that initiates anti-aging by activating autophagy in the body. This renews your body\'s cells and reverses biological age.',
  },
  {
    icon: 'quiz/clock.svg',
    title: 'Thiamine',
    subtitle:
      'Thiamine plays a vital role in boosting immune system, ensuring a steady supply of energy, and lowering hunger hormones & cravings',
  },
  {
    icon: 'quiz/drop.svg',
    title: 'Zinc',
    subtitle:
      'Zinc is known to strengthen hair & nails, revitalize skin, and enhance the overall efficacy of the supplement',
  },
];

const VideoTestimonialsConfig = {
  title: 'But don\'t just take our word for it',
  subtitle:
    'More than 217,205 people have successfully reached their anti-aging goals with Nova. And so can You.',
  cards: [
    {
      title: 'Purchased 3-month supply',
      text: 'I\'ve tried everything, but nothing ever worked like this! In one month I already look 5 years younger!',
      name: 'Jenny F.',
    },
    {
      title: 'Purchased 6-month supply',
      text: 'I wish I’d found these sooner. Nova is truly a game-changer for anti-aging.',
      name: 'Emma C.',
    },
    {
      title: 'Purchased 3-month supply',
      text: 'This is incredible! My cells are renewed, and my skin and hair are thriving like never before!',
      name: 'Grace L.',
    },
    {
      title: 'Purchased 6-month supply',
      text: 'I was stunned by the results! This is the secret anti-aging weapon I was missing!',
      name: 'Sofia H.',
    },
    {
      title: 'Purchased 3-month supply',
      text: 'Only 2 capsules per day, and I have much more energy! Looking 5 years younger and feeling fantastic!',
      name: 'Chloe W.',
    },
  ],
};

const ReviewCardsItems = [
  {
    name: 'Samantha P.',
    city: 'San Francisco, CA',
    title: 'Looking A Decade Younger, Confidence Growing',
    text: 'Ever since I tried Nova, I\'ve noticed a significant change in my appearance, looking 7 years younger in just a few weeks. It\'s incredible how much more confident I feel. Plus, my digestion discomfort and late-night cravings have drastically reduced. Definitely worth trying!',
  },
  {
    name: 'Victoria T.',
    city: 'San Diego, CA',
    title: 'So much more than just anti-aging',
    text: 'I was initially drawn to Nova for its longevity benefits, but I\'ve gained so much more. I\'ve experienced a remarkable boost in my skin, hair and overall health. My sleep has improved, and I wake up feeling refreshed and ready to tackle the day. The combination of spermidine, thiamine, and zinc has truly been a game-changer!',
  },
  {
    name: 'Nancy W.',
    city: 'New York, NY',
    title: 'Revolutionized Eating Habits',
    text: 'My previously uncontrollable cravings have nearly vanished, and find myself naturally opting for healthier food choices. It\'s like my body\'s natural hunger cues have been reset. I\'m amazed at the changes. THANK YOU!!!!',
  },
];

const PeopleAskUsItems = {
  title: 'Got questions?',
  list: [
    {
      question:
        'How do spermidine, zinc, and thiamine join forces to reverse biological aging?',
      answer: [
        {
          type: 'text',
          text: [
            'Picture spermidine, zinc, and thiamine as a dynamic trio, each bringing unique strengths to your longevity routine. Spermidine steps up first, known for its role in activating autophagy and reversing biological aging. Then thiamine comes into play by balancing energy levels, while keeping you active and reducing the likelihood of cravings. Finally, zinc takes the stage enhancing the overall efficacy of the supplement and improving skin, hair, and nail health. Together these natural ingredients work in harmony to support your longevity goals and overall well-being.',
          ],
        },
      ],
    },
    {
      question: 'What sets Nova\'s formula apart from others?',
      answer: [
        {
          type: 'text',
          text: [
            'For starters, our product is crafted in an FDA-registered lab right here in the USA, ensuring high quality. Nova is created with anti-aging in mind. Spermidine reverses biological aging, while thiamine and zinc are known to improve the health of skin, hair, and nails, as well as enhance the overall efficacy of the supplement. This combination offers a multifaceted, anti-aging approach, setting it apart from 97% of other standard supplements in the wellness industry.',
          ],
        },
      ],
    },
    {
      question: 'How fast can I expect to see any noticeable results?',
      answer: [
        {
          type: 'text',
          text: [
            'Everyone\'s body responds uniquely. Results can vary from person to person, but typically, it takes about 1 week for a noticeable anti-aging process to begin.',
            'To experience the full range of benefits, we recommend consistently taking Nova for at least 3 months. Here\'s a glimpse of what you can look forward to shortly after you begin using it:',
          ],
        },
        {
          type: 'bullet_point',
          text: [
            'Reversed biological aging.',
            'Less digestive discomfort and cravings.',
            'Improved skin, hair, and nails health, contributing to a more youthful appearance.',
            'Enhanced, balanced energy levels and better sleep quality.',
          ],
        },
      ],
    },
    {
      question: 'What ingredients are used in Nova?',
      answer: [
        {
          type: 'text',
          text: [
            'Suggested usage: take two capsules with water during your meal, one time per day.',
          ],
        },
        {
          type: 'image',
          imageUrl: 'checkout/sup_facts.png',
        },
        {
          type: 'text',
          text: [
            '<strong>Caution</strong> Consult with your physician if you have any medical conditions. Like most other dietary supplements, Nova is not suitable for pregnant women, breastfeeding mothers, or children, but we are hoping to conduct comprehensive tests in the near future to make our product safe for that demographic. Stop taking the supplement and speak with your physician for advice if you experience mild allergic-type reactions such as nose or eye irritation, feeling short of breath, or itchy skin. If you experience any other uncommon symptoms which you think may be due to taking the supplement, speak with your physician for further advice.',
            '<strong>Storage</strong> Do not use if safety seal is damaged or missing. Store in a cool, dry place, away from heat/moisture',
          ],
        },
      ],
    },
    {
      question: 'How do I cancel my subscription if I need to?',
      answer: [
        {
          type: 'text',
          text: [
            'You can cancel your subscription by sending a quick email over to our support team at hello@nova.health, and we’ll handle the cancellation for you, hassle-free.',
          ],
        },
      ],
    },
    {
      question:
        'Will my biological aging accelerate when I stop using Nova supplements?',
      answer: [
        {
          type: 'text',
          text: [
            'After discontinuing Nova, your biological aging may gradually return to its previous rate, and the anti-aging benefits may diminish. The duration of these effects can vary, especially if you\'ve been using Nova for a long time, as your body remains nutrient-dense from prolonged use.',
          ],
        },
      ],
    },
  ],
};

const FooterInfo = {
  text: 'Disclaimer: After discontinuing Nova, the anti-aging benefits may diminish. The duration of these effects can vary, especially if you\'ve been using Nova for a long time, as your body remains nutrient-dense from prolonged use.',
  footerEnd: '© 2024 Nova Health. All rights reserved.',
};

const WithWithoutContent = {
  text: '<strong>9 out of 10</strong> people in the US lack spermidine, leading to accelerated biological aging. We\'ve already helped over 217,205 of them. Reverse your biological age, revitalize skin & hair, and set the stage for a younger you.',
  imageUrl: 'quiz/benefits_covered.png',
  redCardItems: {
    title: 'Without Nova',
    items: [
      'Accelerated biological aging',
      'Digestive discomfort',
      'Thinning hair & skin issues',
      'Sleep issues',
      'Fatigue',
    ],
  },
  greenCardItems: {
    title: 'With Nova',
    items: [
      'Reversed biological aging',
      'Healthy gut',
      'Healthy hair and skin',
      'Better sleep',
      'Highly energized',
    ],
  },
};

const DisclaimerText = [];

const LandingOptions = {
  title: '<strong>A revolutionary way to reverse biological age</strong>',
  description:
    'Take <strong>1-minute</strong> free quiz to find out your biological age and how Nova can help you stay young',
  label: 'Start by selecting your gender',
  bgColor: '#FBFBFF',
  options: [
    {
      label: 'Male',
      value: 'male',
      color: 'light0',
      textColor: '#2A4B42',
    },
    {
      label: 'Female',
      value: 'female',
      color: 'green110',
    },
  ],
};

const IndexPage = () => {
  const { isTablet } = useQuery();

  const handleLandingOptionSelect = (answer: any) => {
    if (typeof window !== 'undefined') {
      // We're in the browser environment

      // Extract URL parameters using window.location.search
      const searchParams = new URLSearchParams(window.location.search);
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      // Optionally, navigate to another page with the parameters
      sessionStorage.setItem('genderAnswer', answer.label.toLowerCase());
      const queryString = new URLSearchParams(params).toString();
      navigate(`/start/?${queryString}&q=2`);
    }
  };

  // Function to handle button click and smoothly scroll to the top
  const handleScrollToTop = () => {
    if (typeof window !== 'undefined') {
      // Check if we're in a browser environment

      // Use window.scrollTo to smoothly scroll to the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  return (
    <>
      <Seo />
      <main>
        <Landing
          hideFooter
          question={LandingOptions}
          onOptionSelect={handleLandingOptionSelect}
        />
        <ContentWrapper
          $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
          $bgColor={'#FEF3F3'}
        >
          <WithWithoutLanding {...WithWithoutContent} />
          <StyledButton onClick={handleScrollToTop}>Take the quiz</StyledButton>
        </ContentWrapper>
        <ContentWrapper
          $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
          $bgColor="#436460"
        >
          <Container>
            <MainTitle type="h2">
              The best natural ingredients, backed by science
            </MainTitle>
            {items.map((item, index) => (
              <Wrapper key={index}>
                <StyledIcon src={item.icon} alt="icon" />
                <Title type="h3">{item.title}</Title>
                <SubTitle>{item.subtitle}</SubTitle>
              </Wrapper>
            ))}
          </Container>
          <StyledButton
            bgColor="#FEF3F3"
            fontColor="#000"
            onClick={handleScrollToTop}
          >
            Take the quiz
          </StyledButton>
        </ContentWrapper>
        <ContentWrapper
          $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
          $bgColor="#436460"
        >
          <VideoTestimonials {...VideoTestimonialsConfig} />
        </ContentWrapper>
        <ContentWrapper
          $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
          $bgColor="#FEF3F3"
        >
          <ReviewCards
            fontColor="dark100"
            scrollTo={handleScrollToTop}
            buttonText="Take the quiz"
            title="Product Approved by USA Citizens"
            cards={ReviewCardsItems}
          />
        </ContentWrapper>
        <ContentWrapper
          $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
          $bgColor="#FEF3F3"
        >
          <CenterContent
            $width={'776px'}
            $maxWidth={isTablet ? '100%' : '776px'}
          >
            <PeopleAskUs {...PeopleAskUsItems} />
          </CenterContent>
        </ContentWrapper>
        <ResultsFooter bgColor={'#FEF3F3'} fontColor={'#000'} {...FooterInfo} />
        <LCFooter
          disclaimerText={['© 2024 Nova Health. All rights reserved']}
        />
      </main>
    </>
  );
};

export default IndexPage;

const StyledButton = styled(StartNowButton)<{
  bgColor?: string;
  fontColor?: string;
}>`
  width: 100%;
  max-width: 21.4375rem;
  margin: 3rem auto 0rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  @media ${mobile} {
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;

const ContentWrapper = styled.section<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  padding: ${({ $padding }) => $padding || 0};
`;

const Paragraph = styled(Text)``;

const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
`;

const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem 1rem;
`;

const MainTitle = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #fff;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.25rem;
  @media ${mobile} {
    margin-top: 1.5rem;
  }
`;

const SubTitle = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 200;
  line-height: 130%;
`;

const Title = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  line-height: 130%;
  margin-bottom: 0.5rem;
`;

const StyledIcon = styled(DynamicImage)`
  margin-bottom: 0.75rem;
`;

//
