import * as ReactDOMServer from 'react-dom/server';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { DynamicImage } from 'components';
import { mobile, smLaptop, tablet, useQuery } from 'styles/breakpoints';

interface SliderImage {
  srcPath: string;
  alt: string;
  delay?: number;
}
interface Slider {
  imageNameArray: Array<SliderImage>;
  isCheckoutValueVersion?: boolean;
}

export const ImageSlider: React.FC<Slider> = ({
  imageNameArray,
  isCheckoutValueVersion = false,
}) => {
  const { isMobile, isTablet } = useQuery();

  const swiperRef = useRef<SwiperRef>(null);

  const navigatePrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const navigateNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const modules = isCheckoutValueVersion
    ? [Pagination]
    : [Pagination, Autoplay];
  const pagination = {
    clickable: true,
    // if index will be removed function will crash
    renderBullet: (_index: number, className: string) =>
      ReactDOMServer.renderToStaticMarkup(
        <Bullet hoverBackground={'#FFF'} className={className} />,
      ),
  };

  return (
    <Box width="100%" height="100%">
      <Wrapper
        height={isTablet ? '100%' : '16.05138rem'}
        width={isTablet ? '100%' : ': 19.29256rem'}
        isCheckoutValueVersion={isCheckoutValueVersion}
      >
        <StyledSwiper
          ref={swiperRef}
          pagination={pagination}
          loop
          autoplay={{ disableOnInteraction: true }}
          speed={1000}
          modules={modules}
        >
          {imageNameArray.map(({ srcPath, alt, delay }, index) => (
            <StyledSwiperSlide
              key={`${srcPath}_${index}`}
              data-swiper-autoplay={delay}
            >
              <StyledDynamicImage
                src={`${srcPath}`}
                alt={alt}
                height={
                  // eslint-disable-next-line no-nested-ternary
                  !isMobile ? '100%' : isCheckoutValueVersion ? '150%' : '100%'
                }
              />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </Wrapper>
    </Box>
  );
};

const Box = styled.div<{ width?: string; height?: string }>`
  width: 19.29256rem;
  height: 17.05138rem;
  @media ${tablet} {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;

const Wrapper = styled(Box)<{
  isCheckoutValueVersion: boolean;
  height: string;
}>`
  .swiper-initialized {
    overflow: hidden;
    @media ${tablet} {
      overflow: initial;
    }
  }

  .swiper-pagination {
    position: absolute;

    bottom: ${({ isCheckoutValueVersion }) =>
      isCheckoutValueVersion ? '-10.5rem !important' : '1.125rem !important'};

    @media ${mobile} {
      bottom: ${({ isCheckoutValueVersion }) =>
        isCheckoutValueVersion ? '-3.5rem !important' : '0 !important'};
      margin-bottom: -2rem;
    }
    @media ${tablet} {
      bottom: ${({ isCheckoutValueVersion }) =>
        isCheckoutValueVersion ? '-4rem !important' : '0 !important'};
      margin-bottom: '-2rem';
    }
    @media ${smLaptop} {
      bottom: 0 !important;
      margin-bottom: -2rem;
    }
  }
`;

const Bullet = styled(Box)<{ hoverBackground: string }>`
  opacity: 1;
  height: 0.5rem;
  width: 0.5rem;
  border: 1px solid #fff;
  border-radius: 50px;
  background-color: transparent;

  &:hover {
    background-color: ${({ hoverBackground }) => hoverBackground};
  }

  &.swiper-pagination-bullet-active {
    background-color: ${({ hoverBackground }) => hoverBackground};
  }
`;

const Button = styled(Box)`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3.125rem;
  transition: 0.2s all;

  background-color: 'rgba(0, 0, 0, 0.4)';
  z-index: 2;

  &:hover {
    background-color: 'rgba(0, 0, 0, 0.5)';

    svg {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
`;

const StyledDynamicImage = styled(DynamicImage)``;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 100%;
  height: 100%;
`;

const Flex = styled(Box)<{ isCheckoutValueVersion: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute
  height: ${({ isCheckoutValueVersion }) =>
    isCheckoutValueVersion ? '150%' : '100%'};
  width: 100%;
`;
