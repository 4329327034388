import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile } from 'styles/breakpoints';

interface ExpectationsProps {
  title: string;
  items: { icon: string; title: string; subtitle: string }[];
}

const MainTitle = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #fff;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const Title = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  line-height: 130%;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled(Text)`
  max-width: 22.5rem;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 200;
  line-height: 130%;
`;

const StyledIcon = styled(DynamicImage)`
  margin-bottom: 0.75rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.25rem;
  @media ${mobile} {
    margin-top: 1.5rem;
  }
`;

export const Expectations: FC<ExpectationsProps> = ({ title, items }) => (
  <Container>
    <MainTitle>{title}</MainTitle>
    {items.map((item, index) => (
      <Wrapper key={index}>
        <StyledIcon src={item.icon} alt="icon" />
        <Title>{item.title}</Title>
        <SubTitle>{item.subtitle}</SubTitle>
      </Wrapper>
    ))}
  </Container>
);
