import React, {
  FC,
  FocusEvent,
  InputHTMLAttributes,
  Ref,
  useState,
} from 'react';
import styled from 'styled-components';

interface TextInputProps {
  label?: string;
  error?: string;
  inputRef?: Ref<HTMLInputElement>;
}

interface InputProps {
  focused: boolean;
  hasError: boolean;
  required?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  width: inherit;
`;

const Label = styled.label`
  padding-bottom: 0.5rem;
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-left: 1.5rem;
  color: ${({ theme }) => theme.colors.dark100};
`;

const Required = styled.label`
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red100};
`;

const Error = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.red100};
  padding-bottom: 0.5rem;
`;

const Input = styled.input<InputProps>`
  border: 0.06rem solid ${({ theme }) => theme.colors.light100};
  border-radius: 100px;
  outline: none;
  font-family: Inter;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.dark80};
  height: 3rem;
  padding: 0 1.5rem;
  background: ${({ theme }) => theme.colors.light0};
  ::placeholder {
    color: ${({ theme }) => theme.colors.dark10};
  }
`;

export const TextInput: FC<
  TextInputProps & InputHTMLAttributes<HTMLInputElement>
> = ({
  type,
  inputRef,
  disabled,
  label,
  error,
  required,
  onFocus,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const toggleFocus = () => {
    setFocused(!focused);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();

    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();

    onBlur?.(e);
  };

  return (
    <Container>
      {!!label && (
        <Label>
          {label} {required && <Required>*</Required>}
        </Label>
      )}
      {!!error && <Error>{error}</Error>}
      <InputContainer>
        <Input
          ref={inputRef}
          type={type}
          disabled={disabled}
          focused={focused}
          hasError={!!error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      </InputContainer>
    </Container>
  );
};
