import * as Sentry from '@sentry/browser';
import { config } from '../config';
import {
  axiosErrorHandler,
  axiosErrorStatusSelector,
  ErrorTypes,
} from '@frontend/api-wrapper';

export const handleError = (e: Error) => {
  console.log(e);
  if (config.ENV === 'production') {
    Sentry.captureException(e);
  }
};

export function is404Error(e: Error | unknown) {
  let notFound = false;
  axiosErrorHandler(error => {
    if (error.type === ErrorTypes.REQUEST) {
      const responseStatusCode = axiosErrorStatusSelector(error);

      if (responseStatusCode === 404) {
        notFound = true;
      }
    }
  })(e as Error);

  return notFound;
}
