import styled from 'styled-components';

import { black, greyDark } from '../../styles/colors';

interface Styles {
  display?: string;
  padding?: string;
  margin?: string;
  textAlign?: string;
  fontWeight?: number;
}

export const Regular = styled.p<Styles>`
  display: ${props => props.display || 'block'};
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 400};
  color: ${props => props.color || greyDark};
  text-align: ${props => props.textAlign || 'inherit'};

  &.bolder {
    font-weight: 700;
    color: ${props => props.color || black};
  }
`;
