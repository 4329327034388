import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import { mobile, useQuery } from 'styles/breakpoints';
import { DynamicImage } from './images/DynamicImage';
import { Quiz } from 'types/quiz';
import Tracking from 'utils/tracking';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.article`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border: 1.341px solid #2a4b42;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 1.5rem 0;

  // Default styles for all containers: side and bottom borders
  border-left: 1.341px solid #2a4b42;
  border-right: 1.341px solid #2a4b42;
  border-bottom: 1.341px solid #2a4b42;

  // Remove the top border by default
  border-top: none;

  // Target the first question container to add a top border
  &:first-of-type {
    border-top: 1.341px solid #2a4b42;
  }

  // Apply border-radius for the first and last containers
  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media ${mobile} {
    padding: 0.75rem 1rem 0 1rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.75rem;
  margin-bottom: 2.25rem;
  @media ${mobile} {
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    font-size: 1.75rem;
  }
`;

const Question = styled(Text)<{ expanded?: boolean }>`
  color: #1c1c28;
  transition: color 0.2s ease;
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
  width: 100%;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${mobile} {
    font-size: 1.125rem;

    max-width: 16.5625rem;
  }
`;

const List = styled.div``;

const AnswerContainer = styled.div<{ expanded: boolean; maxHeight?: string }>`
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const Answer = styled(Text)<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  -webkit-text-size-adjust: 100%;
  max-width: 704px;
  font-size: 1.25rem;
  @media ${mobile} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

const BulletPoint = styled.li<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  -webkit-text-size-adjust: 100%;
  max-width: 704px;
  font-size: 1.25rem;
  display: flex;
  color: #1c1c28;
  align-items: start;
  @media ${mobile} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &:before {
    content: '•'; /* Custom bullet */
    margin-right: 8px;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 100%;
  max-width: 540px;
  padding-bottom: 1.5rem;
`;

const PeopleAskUs: FC<PeopleAskUsProps> = ({ title, list }) => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const [rotatedIcons, setRotatedIcons] = useState<{
    [index: number]: boolean;
  }>({});
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const childRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useQuery();
  const quiz =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search).get('qz') ?? Quiz.Main
      : Quiz.Main; // Provide a default value for SSR

  const handleQuestionClick = (index: number, item: Item) => {
    handleClick(index);
    Tracking.logEvent({
      event: 'FAQ-clicks',
      funnel: quiz,
      widget: item.question,
    });
  };

  const handleClick = useCallback((index: number) => {
    setExpandedQuestions(prevState =>
      prevState.includes(index)
        ? prevState.filter(item => item !== index)
        : [...prevState, index],
    );
    setRotatedIcons(prev => ({ ...prev, [index]: !prev[index] }));
  }, []);

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  useEffect(() => {
    // Adjust heights for all expanded questions
    refs.current.forEach((ref, index) => {
      if (ref && expandedQuestions.includes(index)) {
        // Apply calculated maxHeight for expanded items
        ref.style.maxHeight = `${ref.scrollHeight}px`;
      } else if (ref) {
        // Reset maxHeight for collapsed items
        ref.style.maxHeight = '0px';
      }
    });
  }, [expandedQuestions, list.length]);

  const answerRender = (item, expanded, index) => {
    switch (item.type) {
      case 'text':
        return (
          <React.Fragment key={index}>
            {item.text.map(t => (
              <Answer
                color="dark100"
                expanded={expanded}
                key={t}
                dangerouslySetInnerHTML={{ __html: t }}
              />
            ))}
          </React.Fragment>
        );
      case 'bullet_point':
        return (
          <ul key={index}>
            {item.text.map(t => (
              <BulletPoint
                color="dark100"
                expanded={expanded}
                key={t}
                dangerouslySetInnerHTML={{ __html: t }}
              />
            ))}
          </ul>
        );
      case 'image':
        return <StyledImage alt="image" src={item.imageUrl} key={index} />;

      default:
        break;
    }
  };

  const renderItem = (item: Item, index: number) => {
    const key = `ask-us-question-${index}`;
    const expanded = expandedQuestions.includes(index);
    const isRotated = !!rotatedIcons[index];

    return (
      <QuestionContainer
        key={key}
        onClick={() => handleQuestionClick(index, item)}
        expanded={expanded}
      >
        <TitleContainer>
          <QuestionIconContiner>
            <MinusX />
            <MinusY rotate={isRotated} />
          </QuestionIconContiner>
          <Question>{item.question}</Question>
        </TitleContainer>

        <AnswerContainer
          ref={el => (refs.current[index] = el)}
          expanded={expanded}
        >
          <div ref={childRef}>
            {item.answer.map((answer, index) =>
              answerRender(answer, expanded, `${index}-parent`),
            )}
          </div>
        </AnswerContainer>
      </QuestionContainer>
    );
  };

  return (
    <Container>
      <Title type="h3">{title}</Title>
      <List>{list.map(renderItem)}</List>
    </Container>
  );
};

export default React.memo(PeopleAskUs);

const QuestionIconContiner = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.341px solid #2a4b42;
  border-radius: 50%;
  position: relative; /* Ensure the absolute positioning is relative to this container */
  cursor: pointer;
`;

const MinusX = styled.div`
  width: 17px;
  height: 1px;
  background-color: #2a4b42;
`;

const MinusY = styled.div<{ rotate?: boolean }>`
  height: 17px;
  width: 1px;
  background-color: #2a4b42;
  transform: ${({ rotate }) => (rotate ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  position: absolute; // Adjust positioning as needed
`;
