import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile } from 'styles/breakpoints';
import AmericaSvg from 'assets/images/checkout/america.svg';
import StartButton from '../../results/components/StartNowButton';

interface ReviewCardsProps {
  title: string;
  cards: { name: string; title: string; text: string; city: string }[];
  scrollTo: () => void;
  buttonText?: string;
  fontColor?: string;
}

const ImageArray = [
  'checkout/reviews/first.png',
  'checkout/reviews/second.png',
  'checkout/reviews/third.png',
];

const Container = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  background-color: #fff;
  padding: 1.5rem 1rem 1rem;
  border-radius: 0.25rem;
  width: 100%;
  max-width: 17.456rem;
  @media ${mobile} {
    max-width: unset;
  }
`;

const City = styled(Text)`
  font-size: 0.61069rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #727a81;
`;

const Name = styled(Text)`
  font-size: 0.91606rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #000;
`;

const Title = styled(Text)`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #000;
  margin-top: 0.9rem;
`;

const StyledText = styled(Text)`
  width: 100%;
  max-width: 14.96225rem;
  font-size: 1rem
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: #000;
  margin-top: 0.4rem;
  @media ${mobile} {
    max-width: unset;
  }
`;

export const ReviewCards: FC<ReviewCardsProps> = ({
  scrollTo,
  title,
  cards,
  buttonText,
  fontColor,
}) => (
  <Wrap>
    <StyledTitle fontColor={fontColor}>
      {title}
      <FlagIcon />
    </StyledTitle>
    <Container>
      {cards.map((item, indx) => (
        <Card key={indx}>
          <NameBox>
            <NameContainer>
              <ProfileImage src={ImageArray[indx]} alt="profile_picture" />
              <NameWrap>
                <Name>{item.name}</Name>
                <City>{item.city}</City>
              </NameWrap>
            </NameContainer>
            <VerifiedContainer>
              <StyledCheck
                src={'checkout/reviews/green_check.svg'}
                alt="verified"
              />
              <VerifiedText>Verified</VerifiedText>
            </VerifiedContainer>
          </NameBox>
          <Stars src="checkout/reviews/stars.svg" alt="stars" />
          <Title>{item.title}</Title>
          <StyledText>{item.text}</StyledText>
        </Card>
      ))}
    </Container>
    <StartBu color={'dark100'} onClick={scrollTo}>
      {buttonText || 'Get Nova now'}
    </StartBu>
  </Wrap>
);

const StartBu = styled(StartButton)`
  max-width: 35rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 1.5rem;
`;

const FlagIcon = styled(AmericaSvg)`
@media ${mobile}{
  margin-left: 0.5rem;s
}
`;

const Wrap = styled.div``;

const StyledTitle = styled(Text)<{ fontColor?: string }>`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ fontColor }) => fontColor || '#FFF'};}
  max-width: 863px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  @media ${mobile} {
    display: block;
    margin-bottom: 1rem;
  }
`;

const Stars = styled(DynamicImage)`
  margin-top: 0.6rem;
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NameWrap = styled.div``;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const ProfileImage = styled(DynamicImage)`
  width: 2.03569rem;
  height: 2.03569rem;
`;

const VerifiedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.36rem;
`;

const StyledCheck = styled(DynamicImage)`
  width: 0.81425rem;
  height: 0.81425rem;
`;

const VerifiedText = styled(Text)`
  color: #05a56f;
  font-size: 0.61069rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.855rem */
  text-transform: uppercase;
`;
