import React, { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from 'components/Header';
import { mobile } from 'styles/breakpoints';

interface PageContainerProps {
  bgColor?: string;
  with100vh?: boolean;
}

const Container = styled.div<{
  height: number;
  with100vh?: boolean;
  bgColor?: string;
}>`

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: ${({ with100vh }) => (with100vh ? '100vh' : 'none')}
  // background-color: ${({ bgColor }) => (bgColor ? bgColor : '#fbfbff')};
  background-color: red;
`;

export const PageContainer: FC<PageContainerProps> = props => {
  const height = use100vh();

  return <Container height={height || 0} {...props} />;
};
