import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import first from 'assets/images/checkout/testimonials/first.mp4';
import second from 'assets/images/checkout/testimonials/second.mp4';
import third from 'assets/images/checkout/testimonials/third.mp4';
import forth from 'assets/images/checkout/testimonials/forth.mp4';
import fifth from 'assets/images/checkout/testimonials/fifth.mp4';
import { mobile, useQuery } from 'styles/breakpoints';

interface VideoTestimonialsProps {
  title: string;
  subtitle: string;
  cards: { title: string; text: string; name: string; link: string }[];
}

const VideoMap = [first, second, third, forth, fifth];

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 52.5rem;
  margin: 0 auto;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  overflow: auto;
  width: 100%;
  gap: 0.5rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Box = styled.div`
  position: relative;
  width: 275px;
  height: 400px;
`;

const StyledVideo = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 0.23038rem 0.23038rem;
`;

const ButtonGrid = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  margin: 0 auto;
  margin-top: 1.25rem;
`;

export const VideoTestimonials: FC<VideoTestimonialsProps> = ({
  title,
  subtitle,
  cards,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [points, setPoints] = useState<number>(0);
  const [activePointBox, setActivePointBox] = useState<number>(0);
  const { isMobile } = useQuery();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const VIDEO_WIDTH = isMobile ? 275 + 8 : 280 + 10;

  const handleScroll = () => {
    if (ref.current) {
      const scrollPosition = ref.current.scrollLeft;
      const currentPointBox = Math.ceil(scrollPosition / VIDEO_WIDTH);
      const totalCards = Math.ceil(ref.current.scrollWidth / VIDEO_WIDTH);
      setActivePointBox(
        currentPointBox < totalCards ? currentPointBox : totalCards - 1,
      );
    }
  };

  const handleResize = () => {
    try {
      if (ref.current) {
        const hiddenItems = Math.ceil(
          (ref.current.scrollWidth - ref.current.clientWidth) / VIDEO_WIDTH,
        );

        setPoints(hiddenItems + 1);
        handleScroll();
      }
    } catch (e) {
      setPoints(0);
    }
  };

  useEffect(() => {
    if (isClient && ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);

      handleResize();
      handleScroll();

      return () => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleResize);
        }
      };
    }
  }, [isClient, ref.current]);

  const handleClick = (index: number) => {
    if (ref.current) {
      ref.current.scrollTo({
        left: index * VIDEO_WIDTH,
        behavior: 'smooth',
      });
    }
  };

  const getPointBackground = (isActive: boolean) =>
    isActive ? 'white' : 'transparent';

  const generateArray = (n: number) =>
    Array.from({ length: n }, (_, index) => index);

  return (
    <Container>
      <StyledTitle type="h3">{title}</StyledTitle>
      <StyledSubTitle>{subtitle}</StyledSubTitle>
      <StyledGrid ref={ref}>
        {cards.map((item, index) => (
          <Box key={index}>
            {isClient && (
              <StyledVideo
                crossOrigin="anonymous"
                aria-label="Video"
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
                x-webkit-airplay="allow"
                width="100%"
                height="100%"
              >
                <source src={VideoMap[index]} type="video/mp4" />
              </StyledVideo>
            )}
            <CardContainer>
              <PillImage src={'checkout/nova-bottle.png'} alt="bottle" />
              <CardWrapper>
                <Title>{item.title}</Title>
                <SubTitle>{item.text}</SubTitle>
                <Name>{item.name}</Name>
              </CardWrapper>
            </CardContainer>
          </Box>
        ))}
      </StyledGrid>
      {points > 1 && (
        <ButtonGrid>
          {generateArray(points).map(inx => (
            <PointBox
              key={inx}
              bgColor={getPointBackground(inx === activePointBox)}
              onClick={() => handleClick(inx)}
            />
          ))}
        </ButtonGrid>
      )}
    </Container>
  );
};

const StyledSubTitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: #fff;
  margin-bottom: 1rem;
  max-width: 40.0625rem;
  width: 100%;
`;

const StyledTitle = styled(Text)`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #fff;
  margin-bottom: 0.75rem;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const PillImage = styled(DynamicImage)`
  width: 100%;
  max-width: 3.1rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-size: 0.75938rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #1c1c28;
`;

const SubTitle = styled(Text)`
  font-size: 0.75938rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: #1c1c28;
`;

const Name = styled(Text)`
  font-size: 0.75938rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #1c1c28;
`;

const CardContainer = styled.div`
  display: flex;
  z-index: 1;
  position: absolute;
  padding: 0.75rem 0.5rem 1.25rem;
  background-color: #fef3f3;
  left: 0px;
  right: 0px;
  bottom: 0px;
  gap: 0.5rem;
  height: 100%;
  max-height: 8rem;
  border-radius: 0rem 0rem 0.23038rem 0.23038rem;
`;

const PointBox = styled.div<{ bgColor: string }>`
  width: 8px;
  height: 8px;
  border-radius: 180px;
  border: 1px solid #fff;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
`;
