import styled from 'styled-components';

import { mobile } from '../../styles/breakpoints';
import { black } from '../../styles/colors';

interface Styles {
  padding?: string;
  margin?: string;
  textAlign?: string;
}

export const H1 = styled.h1<Styles>`
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 32px;
  font-weight: 700;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || black};

  &.centered {
    text-align: center;
  }

  @media ${mobile} {
    font-size: 2rem;
  }
`;
