import { createReducer, reduce, set } from '@reduxify/utils';

import { setProjectConfig } from './actions';
import { IConfigState } from './types';

const projectConfig = createReducer<IConfigState['projectConfig']>(
  null,
  reduce(setProjectConfig, set),
);

export default projectConfig;
