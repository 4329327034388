import { createReducer, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  setExperiments,
  setLoading,
  setProducts,
  setQuestions,
} from './actions';
import { IFunnelState } from './types';

const products = createReducer<IFunnelState['products']>(
  [],
  reduce(setProducts, set),
);
const questions = createReducer<IFunnelState['questions']>(
  [],
  reduce(setQuestions, set),
);
const experiments = createReducer<IFunnelState['experiments']>(
  null,
  reduce(setExperiments, set),
);

const loading = createReducer<IFunnelState['loading']>(
  true,
  reduce(setLoading, set),
);

export default combineReducers<IFunnelState>({
  products,
  questions,
  experiments,
  loading,
});
