import styled from 'styled-components';

import { mobile, tablet } from '../../styles/breakpoints';

export const Container = styled.div`
  position: ${props => props.position || 'relative'};
  display: block;
  max-width: 1140px;
  padding: 0 4%;
  margin: ${props => props.margin || 'auto'};

  @media ${tablet} {
    &.plan {
      padding: 0 0 0 4%;
    }
  }

  @media ${mobile} {
    padding: 0 2%;

    &.plan {
      padding: 0 0 0 2%;
    }
  }
`;
