import './assets/fonts/fonts.css';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { configStore } from './state/store';
import { BodyWrapper, withApp } from './components';
import { Modals, Navigations } from './layouts';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import theme from './utils/theme';
import withNoSSR from './components/hoc/withNoSSR';

const { store, persistor } = configStore();

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <BodyWrapper>{withApp(element)}</BodyWrapper>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </StrictMode>
);

export { store };
