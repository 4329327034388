import React, { useMemo } from 'react';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';

export const HeaderCountdown = () => {
  const countdownDate = useMemo(() => Date.now() + 900000, []);
  const { isMobile } = useQuery();
  const renderColon = () => <Colon type={isMobile ? 'h4' : 'h4'}>:</Colon>;

  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      <CountdownUnitsContainer>
        <Time type={isMobile ? 'h4' : 'h4'}>{formatted.hours}</Time>
      </CountdownUnitsContainer>
      {renderColon()}
      <CountdownUnitsContainer>
        <Time type={isMobile ? 'h4' : 'h4'}>{formatted.minutes}</Time>
      </CountdownUnitsContainer>
      {renderColon()}
      <CountdownUnitsContainer>
        <Time type={isMobile ? 'h4' : 'h4'}>{formatted.seconds}</Time>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  return (
    <HeaderCountDown>
      <CountdownTitle>Limited price for:</CountdownTitle>
      <ReactCountdown date={countdownDate} renderer={renderer} />
    </HeaderCountDown>
  );
};

const HeaderCountDown = styled.div``;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-family: 'Avenir', 'Open Sans';
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Time = styled(Text).attrs({
  color: 'dark100',
})`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 900;
  @media ${mobile} {
  }
`;

const Colon = styled(Time)`
  margin: 0;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.primary};
`;

const CountdownTitle = styled.p`
  font-size: 12px;
  font-weight: 700;
`;
