import React, { FC } from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import styled from 'styled-components';

const RadioInputStyled = styled(Radio)`
  &.MuiRadio-root {
    color: ${({ theme, checked }) =>
      !checked ? theme.colors.dark0 : theme.colors.dark0};
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const RadioInput: FC<RadioProps> = props => (
  <RadioInputStyled color="default" {...props} />
);
