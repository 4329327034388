import { AnyAction, combineReducers } from 'redux';

import funnelReducer from './funnel/reducer';
import projectConfig from './config/reducer';
import userReducer from './user/reducer';
import paymentReducer from './payment/reducer';

const combinedReducer = combineReducers({
  funnel: funnelReducer,
  config: projectConfig,
  user: userReducer,
  payment: paymentReducer,
});

export const rootReducer = (state: any, action: AnyAction) =>
  combinedReducer(state, action);
