exports.components = {
  "component---src-pages-cases-cases-ts": () => import("./../../../src/pages/cases/cases.ts" /* webpackChunkName: "component---src-pages-cases-cases-ts" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-cases-swiper-button-tsx": () => import("./../../../src/pages/cases/SwiperButton.tsx" /* webpackChunkName: "component---src-pages-cases-swiper-button-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-mobile-index-tsx": () => import("./../../../src/pages/contact-mobile/index.tsx" /* webpackChunkName: "component---src-pages-contact-mobile-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-privacy-mobile-index-tsx": () => import("./../../../src/pages/privacy-mobile/index.tsx" /* webpackChunkName: "component---src-pages-privacy-mobile-index-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-single-index-tsx": () => import("./../../../src/pages/single/index.tsx" /* webpackChunkName: "component---src-pages-single-index-tsx" */),
  "component---src-pages-single-mobile-index-tsx": () => import("./../../../src/pages/single-mobile/index.tsx" /* webpackChunkName: "component---src-pages-single-mobile-index-tsx" */),
  "component---src-pages-start-404-tsx": () => import("./../../../src/pages/start/404.tsx" /* webpackChunkName: "component---src-pages-start-404-tsx" */),
  "component---src-pages-start-calculating-index-tsx": () => import("./../../../src/pages/start/calculating/index.tsx" /* webpackChunkName: "component---src-pages-start-calculating-index-tsx" */),
  "component---src-pages-start-checkout-email-index-tsx": () => import("./../../../src/pages/start/checkout-email/index.tsx" /* webpackChunkName: "component---src-pages-start-checkout-email-index-tsx" */),
  "component---src-pages-start-checkout-email-tsx": () => import("./../../../src/pages/start/checkout-email/[...].tsx" /* webpackChunkName: "component---src-pages-start-checkout-email-tsx" */),
  "component---src-pages-start-checkout-index-tsx": () => import("./../../../src/pages/start/checkout/index.tsx" /* webpackChunkName: "component---src-pages-start-checkout-index-tsx" */),
  "component---src-pages-start-checkout-special-index-tsx": () => import("./../../../src/pages/start/checkout-special/index.tsx" /* webpackChunkName: "component---src-pages-start-checkout-special-index-tsx" */),
  "component---src-pages-start-checkout-special-tsx": () => import("./../../../src/pages/start/checkout-special/[...].tsx" /* webpackChunkName: "component---src-pages-start-checkout-special-tsx" */),
  "component---src-pages-start-checkout-tsx": () => import("./../../../src/pages/start/checkout/[...].tsx" /* webpackChunkName: "component---src-pages-start-checkout-tsx" */),
  "component---src-pages-start-contacts-tsx": () => import("./../../../src/pages/start/contacts.tsx" /* webpackChunkName: "component---src-pages-start-contacts-tsx" */),
  "component---src-pages-start-discount-index-tsx": () => import("./../../../src/pages/start/discount/index.tsx" /* webpackChunkName: "component---src-pages-start-discount-index-tsx" */),
  "component---src-pages-start-email-index-tsx": () => import("./../../../src/pages/start/email/index.tsx" /* webpackChunkName: "component---src-pages-start-email-index-tsx" */),
  "component---src-pages-start-finalizing-index-tsx": () => import("./../../../src/pages/start/finalizing/index.tsx" /* webpackChunkName: "component---src-pages-start-finalizing-index-tsx" */),
  "component---src-pages-start-home-index-tsx": () => import("./../../../src/pages/start/home/index.tsx" /* webpackChunkName: "component---src-pages-start-home-index-tsx" */),
  "component---src-pages-start-index-tsx": () => import("./../../../src/pages/start/index.tsx" /* webpackChunkName: "component---src-pages-start-index-tsx" */),
  "component---src-pages-start-old-payments-index-tsx": () => import("./../../../src/pages/start/oldPayments/index.tsx" /* webpackChunkName: "component---src-pages-start-old-payments-index-tsx" */),
  "component---src-pages-start-payments-index-tsx": () => import("./../../../src/pages/start/payments/index.tsx" /* webpackChunkName: "component---src-pages-start-payments-index-tsx" */),
  "component---src-pages-start-privacy-policy-tsx": () => import("./../../../src/pages/start/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-start-privacy-policy-tsx" */),
  "component---src-pages-start-quiz-index-tsx": () => import("./../../../src/pages/start/quiz/index.tsx" /* webpackChunkName: "component---src-pages-start-quiz-index-tsx" */),
  "component---src-pages-start-register-index-tsx": () => import("./../../../src/pages/start/register/index.tsx" /* webpackChunkName: "component---src-pages-start-register-index-tsx" */),
  "component---src-pages-start-reminder-tsx": () => import("./../../../src/pages/start/reminder.tsx" /* webpackChunkName: "component---src-pages-start-reminder-tsx" */),
  "component---src-pages-start-results-chart-index-tsx": () => import("./../../../src/pages/start/results-chart/index.tsx" /* webpackChunkName: "component---src-pages-start-results-chart-index-tsx" */),
  "component---src-pages-start-results-index-tsx": () => import("./../../../src/pages/start/results/index.tsx" /* webpackChunkName: "component---src-pages-start-results-index-tsx" */),
  "component---src-pages-start-results-info-index-tsx": () => import("./../../../src/pages/start/results-info/index.tsx" /* webpackChunkName: "component---src-pages-start-results-info-index-tsx" */),
  "component---src-pages-start-results-metabolism-index-tsx": () => import("./../../../src/pages/start/results-metabolism/index.tsx" /* webpackChunkName: "component---src-pages-start-results-metabolism-index-tsx" */),
  "component---src-pages-start-results-reviews-index-tsx": () => import("./../../../src/pages/start/results-reviews/index.tsx" /* webpackChunkName: "component---src-pages-start-results-reviews-index-tsx" */),
  "component---src-pages-start-results-summary-index-tsx": () => import("./../../../src/pages/start/results-summary/index.tsx" /* webpackChunkName: "component---src-pages-start-results-summary-index-tsx" */),
  "component---src-pages-start-results-supplement-index-tsx": () => import("./../../../src/pages/start/results-supplement/index.tsx" /* webpackChunkName: "component---src-pages-start-results-supplement-index-tsx" */),
  "component---src-pages-start-shipping-disclaimer-shipping-tsx": () => import("./../../../src/pages/start/shipping/DisclaimerShipping.tsx" /* webpackChunkName: "component---src-pages-start-shipping-disclaimer-shipping-tsx" */),
  "component---src-pages-start-shipping-index-tsx": () => import("./../../../src/pages/start/shipping/index.tsx" /* webpackChunkName: "component---src-pages-start-shipping-index-tsx" */),
  "component---src-pages-start-shipping-shipping-info-widget-tsx": () => import("./../../../src/pages/start/shipping/ShippingInfoWidget.tsx" /* webpackChunkName: "component---src-pages-start-shipping-shipping-info-widget-tsx" */),
  "component---src-pages-start-shipping-tsx": () => import("./../../../src/pages/start/shipping/[...].tsx" /* webpackChunkName: "component---src-pages-start-shipping-tsx" */),
  "component---src-pages-start-success-index-tsx": () => import("./../../../src/pages/start/success/index.tsx" /* webpackChunkName: "component---src-pages-start-success-index-tsx" */),
  "component---src-pages-start-success-old-index-tsx": () => import("./../../../src/pages/start/successOld/index.tsx" /* webpackChunkName: "component---src-pages-start-success-old-index-tsx" */),
  "component---src-pages-start-terms-of-services-tsx": () => import("./../../../src/pages/start/terms-of-services.tsx" /* webpackChunkName: "component---src-pages-start-terms-of-services-tsx" */),
  "component---src-pages-start-upgrade-index-tsx": () => import("./../../../src/pages/start/upgrade/index.tsx" /* webpackChunkName: "component---src-pages-start-upgrade-index-tsx" */),
  "component---src-pages-start-upgrade-tsx": () => import("./../../../src/pages/start/upgrade/[...].tsx" /* webpackChunkName: "component---src-pages-start-upgrade-tsx" */),
  "component---src-pages-start-upsell-index-tsx": () => import("./../../../src/pages/start/upsell/index.tsx" /* webpackChunkName: "component---src-pages-start-upsell-index-tsx" */),
  "component---src-pages-story-index-tsx": () => import("./../../../src/pages/story/index.tsx" /* webpackChunkName: "component---src-pages-story-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-terms-mobile-index-tsx": () => import("./../../../src/pages/terms-mobile/index.tsx" /* webpackChunkName: "component---src-pages-terms-mobile-index-tsx" */)
}

