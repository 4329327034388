/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { config } from 'config';
import type { ConversionsAPIEventNames } from './types/conversionAPI';
import { getCookiesByKeys } from 'utils/cookies';
import { handleError } from 'utils/error';
import { hashStringToSHA256 } from 'utils/hash';

interface UserDataProps {
  email?: string | null;
  phoneNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  city?: string | null;
  zipCode?: string | null;
  country?: string | null;
  state?: string | null;
}
export const postToConversionsAPI = async (
  eventName: ConversionsAPIEventNames,
  userData?: UserDataProps,
  customData?: any,
  baseDataAddition?: any,
  externalId?: string | null,
  eventId?: string | null,
  userIp?: string | null,
) => {
  const timeStamp = Math.round(new Date().getTime() / 1000);
  const currentURL = window.location.href;
  const cookies = getCookiesByKeys(['_fbc', '_fbp']);
  const userAgent = navigator.userAgent;

  const baseData = {
    event_name: eventName,
    event_time: timeStamp,
    event_id: eventId,
    action_source: 'website',
    event_source_url: currentURL,
    data_processing_options_country: 0,
    data_processing_options_state: 0,
    ...baseDataAddition,
  };

  const hashedUserData: UserDataProps = {};

  if (userData) {
    const userDataKeys = Object.keys(userData);

    for (const key of userDataKeys) {
      const value = userData[key as keyof UserDataProps];
      if (value) {
        const hashedValue = await hashStringToSHA256(value.toLowerCase());
        Object.assign(hashedUserData, { [key]: hashedValue });
      } else {
        Object.assign(hashedUserData, { [key]: value });
      }
    }
  }

  const payload = {
    baseData,
    customData,
    userData: {
      ...hashedUserData,
      userAgent: userAgent,
      cookies: {
        _fbp: cookies._fbp ?? null,
        _fbc: cookies._fbc ?? null,
      },
      externalId: externalId,
      userIp: userIp,
    },
  };

  try {
    // if (config.ENV === 'production' || 'dev') {
    await axios.post(`${config.CAPI_FUNCTION_URL}`, payload);
    // }
  } catch (e) {
    handleError(e);
  }
};
