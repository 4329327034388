import { config } from '../config';
import { reactotronRedux } from 'reactotron-redux';

const init = () => {
  if (config.ENV === 'development' && typeof window !== 'undefined') {
    const Reactotron = require('reactotron-react-js').default;

    return Reactotron.configure().use(reactotronRedux()).connect();
  }
  return null;
};

export const reactotron = init();
