export const black = '#45475A';
export const greyLight = '#F3F3F3';
export const greyDark = '#676767';
export const white = '#FFFFFF';
export const background = '#F3F3F3';
export const primary = '#34B564';
export const error = '#EF3E3E';
export const surface = '#FFFFFF';
export const onSurfaceHigh = '#3E5659';
export const primary1 = '#4BBF68';
export const primary3 = '#A5DFB3';
export const secondary1 = '#BDF2ED';
export const secondary4 = '#E4F1F0';
export const tertiary1 = '#F55F68';

export const tints = {
  black: (tint: number) => `rgba(0, 0, 0, ${tint / 10})`,
  primary: (tint: number) => `rgba(220, 255, 4, ${tint / 10})`,
  white: (tint: number) => `rgba(255, 255, 255, ${tint / 10})`,
};
