import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import XIcon from 'assets/images/quiz/x.svg';
import CheckIconSvg from 'assets/images/quiz/check.svg';
import { mobile } from 'styles/breakpoints';

interface WithWithoutLandingProps {
  text: string;
  imageUrl: string;
  redCardItems: { title: string; items: string[] };
  greenCardItems: { title: string; items: string[] };
}

const Title = styled(Text)`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 1.5rem;
`;

const ItemText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.011rem;
`;

const StyledText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.25rem;
  flex-wrap: wrap;
  @media ${mobile} {
    gap: 1.5rem;
  }
`;

const Wrap = styled.div`
  width: 100%;
  max-width: 21.375rem;
  @media ${mobile} {
    max-width: none;
  }
`;

const Card = styled.div<{ bgColor: string }>`
  width: 100%;
  display: flex;
  max-width: 21.4375rem;
  padding: 1.5rem 1rem;
  flex-direction: column;
  border-radius: 0.5rem;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  @media ${mobile} {
    max-width: none;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 21.4375rem;
  height: 12.1875rem;
  margin-bottom: 1.5rem;
  @media ${mobile} {
    width: 100%;
    height: auto;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  justify-content: center;
  align-self: baseline;
  gap: 0.5rem;
`;

const Xicon = styled(XIcon)``;

const CheckIcon = styled(CheckIconSvg)``;

export const WithWithoutLanding: FC<WithWithoutLandingProps> = ({
  text,
  imageUrl,
  redCardItems,
  greenCardItems,
}) => (
  <Container>
    <Wrap>
      <StyledImage alt="image" src={imageUrl} />
      <StyledText dangerouslySetInnerHTML={{ __html: text }} />
    </Wrap>
    <Card bgColor={'#F47170'}>
      <Title>{redCardItems.title}</Title>
      {redCardItems.items.map((item, index) => (
        <>
          <ItemWrap key={index}>
            <Xicon />
            <ItemText key={item}>{item}</ItemText>
          </ItemWrap>
          {redCardItems.items.length === index + 1 ? null : <WhiteLine />}
        </>
      ))}
    </Card>
    <Card bgColor={'#05A56F'}>
      <Title>{greenCardItems.title}</Title>
      {greenCardItems.items.map((item, index) => (
        <>
          <ItemWrap key={index}>
            <CheckIcon />
            <ItemText key={item}>{item}</ItemText>
          </ItemWrap>
          {greenCardItems.items.length === index + 1 ? null : <WhiteLine />}
        </>
      ))}
    </Card>
  </Container>
);

const WhiteLine = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  height: 1px;
  width: 100%;
  margin: 1rem 0;
`;
