import React, { FC } from 'react';
import styled from 'styled-components';

interface PaymentErrorProps {
  errorMessage?: string;
}

const Container = styled.div`
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: 1;
  transform: translateY(0px);
  height: auto;
`;

const InnerContainer = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-top: 12px;
  padding: 12px;
  color: rgb(234, 67, 53);
  background: rgb(252, 232, 230);
  border-radius: 2px;
`;

const Span = styled.span`
  margin-left: 12px;
  flex: 1 1 0%;
`;

const PaymentError: FC<PaymentErrorProps> = ({ errorMessage }) => {
  if (!errorMessage) return null;
  return (
    <Container aria-hidden="false">
      <InnerContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon"
        >
          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
          <line x1="12" y1="8" x2="12" y2="12" />
          <line x1="12" y1="16" x2="12.01" y2="16" />
        </svg>
        <Span>{errorMessage}</Span>
      </InnerContainer>
    </Container>
  );
};

export default PaymentError;
