import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ImageWrapperProps {
  children: ReactNode;
  width?: string;
  grayScale?: boolean;
  imagePosition?: string;
  height?: string;
  maxWidth?: string;
}

export const ImageWrapper: React.FC<ImageWrapperProps> = ({
  maxWidth,
  width = '100%',
  height = '100%',
  grayScale = false,
  imagePosition = 'static',
  children,
  ...rest
}) => (
  <Image
    grayScale={grayScale}
    width={width}
    height={height}
    maxWidth={maxWidth}
    imagePosition={imagePosition}
    {...rest}
  >
    {children}
  </Image>
);

const Image = styled.div<ImageWrapperProps>`
  ${({ grayScale }) => grayScale && 'mix-blend-mode: luminosity;'};
  ${({ width }) => width};
  ${({ height }) => height};
  ${({ maxWidth }) => maxWidth};
  position: ${({ imagePosition }) => imagePosition};
`;
