import styled from 'styled-components';

interface Styles {
  alignItems?: string;
  justifyContent?: string;
  flexDirection?: string;
  padding?: string;
  margin?: string;
  bg?: string;
}

export const FlexWrapper = styled.div<Styles>`
  display: flex;
  align-items: ${props => props.alignItems || 'stretch'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  flex-direction: ${props => props.flexDirection || ''};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
`;
