import { createReducer, reduce, set } from '@reduxify/utils';

import { setPaymentGateway } from './actions';
import { IPaymentState } from './types';
import { combineReducers } from 'redux';

const gateway = createReducer<IPaymentState['gateway']>(
  null,
  reduce(setPaymentGateway, set),
);

export default combineReducers<IPaymentState>({ gateway });
