import styled from 'styled-components';

import { black } from '../../styles/colors';

interface Styles {
  display?: string;
  padding?: string;
  margin?: string;
  fontWeight?: number;
  textAlign?: string;
}

export const Caption = styled.p<Styles>`
  display: ${props => props.display || 'block'};
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-size: 18px;
  font-weight: ${props => props.fontWeight || 400};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || black};
  vertical-align: middle;
`;
